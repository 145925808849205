import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Select, { StylesConfig } from 'react-select';

import { AppThunkDispatch } from 'types';
import { Department } from 'types/Common/Companies';

import {
  CE_VERIFIED_STATUSES,
  JOB_STATUS_OPTIONS,
  JOB_TYPE_STATUSES,
  PAID_ONLY_STATUSES,
  VERIFIED_STATUSES,
} from 'Constants/timesheet';
import { actions } from 'Services';
import { getDepartments } from 'Services/app/actions';
import DatePicker from 'components/Picker/DatePicker';
import { ReduxState, useAppSelector } from 'createStore';

import DepartmentAsyncSearch from '../Components/Controls/DepartmentAsyncSearch';

type Props = {};

const TimesheetHeader = (props: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const searchParams = useAppSelector((state) => state.timesheets.search_options);

  const updateFilters = (options: Partial<ReduxState['timesheets']['search_options']> = {}) =>
    dispatch(actions.TimesheetsActions.updateFilters(options));

  const handleChangeDepartment = (item: Department) => {
    updateFilters({ department: item?.id || '' });
  };

  const handleChangeSelectSearchParams = useCallback(
    (name) => {
      return (item) => {
        updateFilters({ [name]: item ? item.value : '' });
      };
    },
    [searchParams]
  );

  const dateChange = (data: { from_datetime: string; to_datetime: string }) => {
    updateFilters({
      from_datetime: data.from_datetime,
      to_datetime: data.to_datetime,
    });
  };

  const styles: Partial<StylesConfig<{ label: string; value: any }, false>> = {
    menu: (base) => ({ ...base, zIndex: 10 }),
  };

  useEffect(() => {
    dispatch(getDepartments({ order_field: 'priority', order_direction: 'asc' }));
  }, []);

  return (
    <>
      <div className="mx-2" style={{ width: 225, display: 'flex' }}>
        <DatePicker
          onChange={dateChange}
          from_datetime={searchParams.from_datetime}
          to_datetime={searchParams.to_datetime}
          showClearDates
        />
      </div>
      <div className="mx-2" style={{ width: 200 }}>
        <DepartmentAsyncSearch onSelect={handleChangeDepartment} departmentId={searchParams.department} />
      </div>

      <div className="mx-2" style={{ width: 140 }}>
        <Select
          isClearable
          options={CE_VERIFIED_STATUSES}
          value={CE_VERIFIED_STATUSES.find((v) => v.value === searchParams.supervisor_verified)}
          placeholder={'CE Verified'}
          onChange={handleChangeSelectSearchParams('supervisor_verified')}
          styles={styles}
        />
      </div>
      <div className="mx-2" style={{ width: 180 }}>
        <Select
          isClearable
          options={VERIFIED_STATUSES}
          value={VERIFIED_STATUSES.find((v) => v.value === searchParams.verifiedStatus)}
          placeholder={'CES Verified Status'}
          onChange={handleChangeSelectSearchParams('verifiedStatus')}
          styles={styles}
        />
      </div>
      <div className="mx-2" style={{ width: 170 }}>
        <Select
          isClearable
          options={PAID_ONLY_STATUSES}
          value={PAID_ONLY_STATUSES.find((v) => v.value === searchParams.paidOnlyStatus)}
          placeholder={'Paid Status'}
          onChange={handleChangeSelectSearchParams('paidOnlyStatus')}
          styles={styles}
        />
      </div>

      <div className="mx-2" style={{ width: 170 }}>
        <Select
          isClearable
          options={JOB_STATUS_OPTIONS}
          value={JOB_STATUS_OPTIONS.find((v) => v.value === searchParams.jobStatus)}
          placeholder={'Job Status'}
          onChange={handleChangeSelectSearchParams('jobStatus')}
          styles={styles}
        />
      </div>
      <div className="mx-2" style={{ width: 140 }}>
        <Select
          isClearable
          options={JOB_TYPE_STATUSES}
          value={JOB_TYPE_STATUSES.find((v) => v.value === searchParams.jobType)}
          placeholder={'Job Type'}
          onChange={handleChangeSelectSearchParams('jobType')}
          styles={styles}
        />
      </div>
    </>
  );
};

export default TimesheetHeader;
