import moment from 'moment';

export const FORMATS = {
  date: 'MM/DD/YY',
  dateWithFullYear: 'MM/DD/YYYY',
  time: 'HH:mm',
  timeWithAmPm: 'hh:mm A',
  datetime: `MM/DD/YY HH:mm`,
  timesheetDate: `YYYY-MM-DD HH:mm`,
  jobCreateDate: 'YYYY-MM-DDTHH:mm',
  masterTimesheetDateTimePicker: 'MM.DD.YYYY hh:mm A',
  masterTimesheetDateValue: 'YYYY-MM-DD HH:mm:ss',
  masterTimesheetDateTimeChanges: 'MM/DD/YY • hh:mm A',
  chatMessageCreateAt: 'MM/DD/YY hh:mm A',
} as const;

export const formatDate = (date, format = FORMATS.date) => {
  return date ? moment(date).format(format) : 'None';
};

export const renderTime = (time: any) => {
  if (isNaN(time)) {
    return time;
  }

  return time.toFixed(2);
};
