import { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled, TextField, InputAdornment, IconButton, TextFieldProps, alpha } from '@mui/material';

import { DatasetTestIDs } from 'Constants/tests';

export const AppInputField = styled((props: Omit<TextFieldProps, 'variant' | 'InputLabelProps'>) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      autoComplete="new-password"
      role="presentation"
      {...props}
      inputProps={{
        ...props.inputProps,
        ...(props.type === 'password' && { type: showPassword ? 'text' : 'password' }),
      }}
      InputProps={{
        ...props.InputProps,
        ...(props.type === 'password' && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
                sx={{ zIndex: 1 }}
                {...DatasetTestIDs.components.profileForm.profilePasswordTab.buttons.showPassword}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
    />
  );
})(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: 0,
    borderRadius: '8px',
    fontFamily: 'Poppins Medium, system-ui, sans-serif',
    color: '#404B5B',
    boxShadow: '0px 4px 3.2px 0px #00000010',
    transition: 'boxShadow 0.15s ease-in-out',
    backgroundColor: 'white',

    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#7D98AE66',
      },
    },
  },

  '& .MuiOutlinedInput-input': {
    padding: '14px 16px',
    fontSize: '13px',
    height: 20,
    opacity: 1,
    borderRadius: '8px',

    '&::placeholder': {
      color: '#7D98AE',
      opacity: 1,
      fontFamily: 'Poppins Medium, system-ui, sans-serif',
    },

    [theme.breakpoints.down('md')]: {
      padding: '9px 16px',
    },
  },

  '& .MuiOutlinedInput-root.Mui-focused': {
    boxShadow: `0 .5px 0 2px #2F83FF33`,
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: theme.palette.primary.main,
    },
  },

  '& .MuiOutlinedInput-root.Mui-error': {
    color: theme.palette.text.primary,
    backgroundColor: '#FEF1F299',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },

    '&.Mui-focused': {
      boxShadow: `0 .5px 0 2px ${alpha(theme.palette.error.main, 0.3)}`,
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    transition: 'border 0.15s ease-in-out',
    borderColor: '#E8EDF1',

    '& > legend': {
      maxWidth: 0,
    },
  },

  '& .MuiInputLabel-outlined': {
    position: 'static',
    transform: 'none',
    marginBottom: 10,
    fontSize: '12px',
    fontFamily: 'Poppins Medium, system-ui, sans-serif',
    color: '#404B5B',
    height: 18,

    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },

    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  },

  '& .MuiInputBase-sizeSmall': {
    '& .MuiOutlinedInput-input': {
      padding: '9px 16px',
      fontSize: '13px',
      height: 20,
      opacity: 1,
    },
  },

  '& .Mui-disabled': {
    pointerEvents: 'none',
    boxShadow: 'none',

    '& .MuiOutlinedInput-input': {
      backgroundColor: theme.palette.secondary.light,
      color: '#6B8396',
      '-webkit-text-fill-color': '#6B8396',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E8EDF1',
    },
  },

  '& .MuiFormHelperText-root': {
    margin: '5px 0 0 0',
    fontFamily: 'Poppins Medium, system-ui, sans-serif',
  },
}));

export default AppInputField;
