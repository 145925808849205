import React, { FC } from 'react';

import { ImmutableObject } from 'seamless-immutable';

import { ChangesLogItem } from 'types/Common/History';

import { normalizeFieldName } from 'components/ChangesHistory/helpers';

import FieldChangesLine from '../FieldChangesLine/FieldChangesLine';
import styles from './LogContent.module.scss';

type Props = {
  log: ImmutableObject<ChangesLogItem>;
  jobType?: string;
};

const LogContent: FC<Props> = ({ log, jobType }) => {
  const headerLine = {
    fieldName: 'Field Name',
    oldValue: 'Old Value',
    newValue: 'New Value',
  };

  return (
    <article className={styles.logContent}>
      <p className={styles.logContent__body}>{log?.body}</p>

      {Boolean(log?.fields?.length) && (
        <>
          <span className={styles.mobileOnly}>
            <b>
              Changed field
              {log?.fields?.length > 1 ? 's' : ''}:{' '}
            </b>
            {log?.fields?.map((field) => normalizeFieldName(field.fieldName)).join(', ')}
          </span>
          <ul className={styles.logContent__changesList}>
            {[headerLine, ...log.fields].map((field) => (
              <FieldChangesLine field={field} jobType={jobType} key={field.fieldName} />
            ))}
          </ul>
        </>
      )}
      <p className={styles.logContent__details}>
        <span>
          <b>Source: </b>
          {log?.source}
        </span>
        <span>
          <b>Change Made By: </b>
          {log?.updaterName || log?.added_by_user_name}
        </span>
        <span className={styles.mobileOnly}>
          <b>Updated at: </b>
          {log?.updatedAt || log?.created_at}
        </span>
      </p>
    </article>
  );
};

export default LogContent;
