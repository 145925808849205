import { FC, useMemo, useState } from 'react';

import classNames from 'classnames';
import { ImmutableArray } from 'seamless-immutable';

import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, CircularProgress, Tooltip } from '@mui/material';

import { ChangesLogItem } from 'types/Common/History';

import { StyledIconButton } from 'Containers/Job/styled';
import UserPermissions from 'Utils/PermissionsHelper';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';

import styles from './ChangesHistory.module.scss';
import LogContent from './components/LogContent/LogContent';
import LogDate from './components/LogDate/LogDate';
import LogTimeline from './components/LogTimeline/LogTimeline';

type Props = {
  changesLog: ImmutableArray<ChangesLogItem> | ChangesLogItem[];
  jobType?: string;
};

type ConditionalProps =
  | {
      isDownloadable: true;
      onDownloadHistoryPdf: () => Promise<void>;
    }
  | {
      isDownloadable?: never;
      onDownloadHistoryPdf?: never;
    };

const ChangesHistory: FC<Props & ConditionalProps> = ({
  changesLog,
  jobType = '',
  onDownloadHistoryPdf = () => Promise.resolve(),
  isDownloadable = false,
}) => {
  const userCanSeeDeletedNotes = UserPermissions.has.see_deleted_notes;
  const [showDeletedNotes, setShowDeletedNotes] = useState(false);
  const { inProcess, promiseWrapper } = useProcessing();

  const hasDeletedNotes = useMemo(() => {
    return changesLog?.some(({ is_deleted }) => is_deleted) || false;
  }, [changesLog]);

  const toggleShowDeletedNotes = () => {
    setShowDeletedNotes((prev) => !prev);
  };

  const handleDownloadHistoryPdf = async () => {
    try {
      await promiseWrapper(onDownloadHistoryPdf());
    } catch (error) {
      showErrorMessage(error);
    }
  };

  if (!changesLog?.length) {
    return (
      <ul className={styles.ChangesHistory}>
        <div className={styles.noHistory}>No History</div>
      </ul>
    );
  }

  return (
    <>
      {/* Additional sticky actions */}
      <Box position="relative">
        <Box
          position="absolute"
          display="flex"
          gap={1}
          top={20}
          right={24}
          flexDirection="column"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'row',
              top: 12,
            },
          })}
        >
          {userCanSeeDeletedNotes && hasDeletedNotes && (
            <Tooltip title={`${showDeletedNotes ? 'Hide' : 'Show'} deleted notes`} disableInteractive>
              <StyledIconButton onClick={toggleShowDeletedNotes} color="primary" backdropBlur>
                {showDeletedNotes ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </StyledIconButton>
            </Tooltip>
          )}
          {isDownloadable && (
            <Tooltip title={!inProcess && 'Download history PDF'} disableInteractive>
              <StyledIconButton disabled={inProcess} color="primary" onClick={handleDownloadHistoryPdf} backdropBlur>
                {inProcess ? <CircularProgress size={14} /> : <DownloadIcon />}
              </StyledIconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {/* History list */}
      <ul className={styles.ChangesHistory}>
        {changesLog
          .filter(({ is_deleted }) => showDeletedNotes || !is_deleted)
          .map((log) => (
            <li className={classNames([styles.logBox, log.is_deleted && styles.logDeleted])} key={log.id}>
              <LogDate date={log.updatedAt || log.created_at} isDeleted={Boolean(log.is_deleted)} />
              <LogTimeline isDeleted={Boolean(log.is_deleted)} />
              <LogContent log={log} jobType={jobType} />
            </li>
          ))}
      </ul>
    </>
  );
};

export default ChangesHistory;
