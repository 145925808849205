import React, {
  PropsWithChildren,
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type OpenPopover = (id: string, children: JSX.Element) => void;

type PopoverContextType = {
  openPopover: OpenPopover;
  closePopover: (id: string) => void;
  closeAll: () => void;
};

const PopoverContext = createContext<PopoverContextType>({
  openPopover: () => {},
  closePopover: () => {},
  closeAll: () => {},
});

export type WithMenusProps = PopoverContextType;

export const usePopoverContext = () => useContext(PopoverContext);

export const withPopover = (Component: React.ComponentType) => {
  const WithPopover = (props: any) => {
    const context = usePopoverContext();
    return <Component {...context} {...props} />;
  };
  return WithPopover;
};

const PopoverProvider = ({ children }: PropsWithChildren) => {
  const [renderChildren, setRenderChildren] = useState(new Map<string, React.ReactNode>());
  const openPopover: OpenPopover = useCallback((id, element) => {
    setRenderChildren((menus) => {
      const newMenus = new Map(menus);
      newMenus.set(id, cloneElement(element, { key: id }));
      return newMenus;
    });
  }, []);

  const closePopover = useCallback((id: string) => {
    setRenderChildren((menus) => {
      const newMenus = new Map(menus);
      newMenus.delete(id);
      return newMenus;
    });
  }, []);

  const closeAll = useCallback(() => {
    setRenderChildren(new Map());
  }, []);

  const value = useMemo(() => ({ openPopover, closePopover, closeAll }), [openPopover, closePopover, closeAll]);

  return (
    <PopoverContext.Provider value={value}>
      {children}
      {[...renderChildren.values()]}
    </PopoverContext.Provider>
  );
};

export default PopoverProvider;
