import ApiService from 'Services/HttpService';

class CommentsAPI {
  getAll = ({ entity_id = 0, entity_type = '' }) => {
    return ApiService.GET('/comments', { entity_id, entity_type });
  };

  create = (newComment: { entity_id: number; entity_type: string; comment: string }) => {
    return ApiService.POST_JSON('/comments', newComment);
  };

  update = (commentId: number, comment: string) => {
    return ApiService.PUT(`/comments/${commentId}`, { comment });
  };

  delete = (commentId: number) => {
    return ApiService.DELETE(`/comments/${commentId}`);
  };
}

export default new CommentsAPI();
