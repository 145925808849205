import { useCallback, useEffect, useMemo, useState } from 'react';

import { ImageWithAuthor } from 'types/Location';

import { JobType } from 'Constants/job';
import { AppAPI } from 'Services/API';
import picture from 'Utils/picture';
import { ImagePreviewProps, SaveRotatedImageParams } from 'components/ImagePreview/types';
import ImagePreviewConsumer from 'context/ImagePreview/Consumer';

import HeaderButtons from './HeaderButtons';
import ImagePreviewRow, { ShowRemoveButton } from './ImagePreviewRow';
import ImageTitleHeader from './ImageTitleHeader';
import ThumbnailImages from './ThumbnailImages';

type Props<ImageType extends ImageWithAuthor = ImageWithAuthor> = {
  images: ImageType[];
  splitPreviewImagesTo?: number;
  imageIndex?: number;
  onChangeImage?: (index: number) => void;
  onClose?: () => void;
  showImagesRow?: boolean;
  onRemoveImage?: (image: ImageType, index: number) => void;
  canRemoveImage?: ShowRemoveButton<ImageType>;
  onSaveRotatedImage?: (params?: {
    image: ImageType;
    index: number;
    update: { url: string; degree: number };
  }) => void | Promise<any>;
  jobType?: JobType | string;
  onRejectPhoto?: (image: ImageType) => void;
  canRejectPhoto?: boolean;
  canSaveRotate?: boolean;
  getDownloadedImageName?: ImagePreviewProps<ImageType>['getDownloadedImageName'];
  size?: 'small' | 'medium' | 'large';
};

const createImageWithDate = (image: ImageWithAuthor) => ({
  ...image,
  url: picture.addDate(image.url, image.updated_at),
});

const removeDateFromImage = (image: ImageWithAuthor) => ({
  ...image,
  url: picture.removeDate(image.url),
});

const ImageViewer = function <ImageType extends ImageWithAuthor>({
  imageIndex = -1,
  onChangeImage = () => {},
  onClose = () => {},
  images: defaultImages = [],
  onSaveRotatedImage = () => Promise.resolve(),
  onRejectPhoto = () => {},
  canRejectPhoto = false,
  splitPreviewImagesTo = -1,
  showImagesRow = false,
  size = 'large',
  canSaveRotate = true,
  onRemoveImage = () => {},
  canRemoveImage = false,
  getDownloadedImageName = () => '',
  jobType,
}: Props<ImageType>) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(imageIndex);
  const [images, setImages] = useState(defaultImages);

  useEffect(() => {
    const clearImages = images.map(removeDateFromImage);
    if (
      defaultImages.length !== clearImages.length ||
      defaultImages.some(
        (image, index) => createImageWithDate(image).url !== createImageWithDate(clearImages[index]).url
      )
    ) {
      setImages(defaultImages);
    }
  }, [defaultImages, images]);

  useEffect(() => {
    setSelectedImageIndex(imageIndex);
  }, [imageIndex]);

  const closeViewer = useCallback(() => {
    setSelectedImageIndex(-1);
    onChangeImage(-1);
    onClose();
  }, [onChangeImage, onClose]);

  const changeImage = useCallback(
    (index: number) => {
      setSelectedImageIndex(index);
      onChangeImage(index);
    },
    [onChangeImage]
  );

  const saveRotatedImage = useCallback(
    async (params: SaveRotatedImageParams<ImageType>) => {
      await AppAPI.saveRotatedImage(picture.removeDate(params.update.url), params.update.degree);
      setImages((prev) =>
        prev.map((image, index) => {
          if (index === params.index) {
            return {
              ...image,
              url: picture.addDate(params.update.url, new Date().toISOString()),
            };
          }
          return image;
        })
      );
      onSaveRotatedImage(params);
    },
    [onSaveRotatedImage]
  );

  const modifiedImages = useMemo(() => images.map(createImageWithDate), [images]);

  return (
    <>
      {showImagesRow && (
        <ImagePreviewRow
          images={modifiedImages}
          size={size}
          previewImagesCount={splitPreviewImagesTo}
          onOpenImage={(url) => setSelectedImageIndex(modifiedImages.findIndex((img) => img.url === url))}
          showRemoveButton={canRemoveImage}
          onRemoveImage={onRemoveImage}
        />
      )}
      <ImagePreviewConsumer
        images={modifiedImages}
        onClose={closeViewer}
        imageIndex={selectedImageIndex}
        onChangeImage={changeImage}
        open={selectedImageIndex >= 0}
        ImageTitleHeader={({ image: selectedImg }) => <ImageTitleHeader selectedImg={selectedImg} jobType={jobType} />}
        ThumbnailImages={ThumbnailImages}
        HeaderButtons={(props) => (
          <HeaderButtons {...props} canRejectPhoto={canRejectPhoto} onRejectPhoto={onRejectPhoto} />
        )}
        shouldSaveRotate={canSaveRotate}
        onSaveRotated={saveRotatedImage}
        getDownloadedImageName={getDownloadedImageName}
      />
    </>
  );
};

export default ImageViewer;
