import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Select, { CSSObjectWithLabel, ControlProps, OnChangeValue } from 'react-select';

import { AppThunkDispatch } from 'types';
import { Role } from 'types/Common/User';

import { EROLES } from 'Constants/user';
import { actions } from 'Services';
import { useAppSelector } from 'createStore';

type Props = {
  isMulti?: boolean;
  value: number[];
  onChange: (value: number[]) => void;
  /** Pass () => true to show all roles. By default worker role is hidden */
  optionsFilter?: (value: Role) => boolean;
  defaultValue?: number[];
  label?: string;
  placeholder?: string;
};

type Option = {
  label: string;
  value: number;
};

const RolesAsyncSearch = ({
  value = [],
  onChange = () => {},
  optionsFilter,
  defaultValue = [],
  placeholder = 'Select roles',
  isMulti = false,
}: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const roles = useAppSelector((state) => state.app.roles);
  const rolesMap = useAppSelector((state) => state.app.rolesMap);

  useEffect(() => {
    dispatch(actions.AppActions.getRoles());
  }, []);

  const options = useMemo(
    () =>
      roles
        .asMutable({ deep: true })
        .filter((role) => optionsFilter?.(role) || role.id !== EROLES.worker)
        .map(({ id, name }) => ({ value: id, label: name })),
    [roles]
  );

  const changeValue = useCallback(
    (val: OnChangeValue<Option, typeof isMulti>) => {
      if (isMulti) {
        return onChange(val ? (val as Option[])?.map(({ value }) => value) || [] : []);
      }
      return onChange(val && 'value' in val ? [val.value] : []);
    },
    [onChange]
  );

  const getValue = useCallback(
    (value: number[]) => {
      if (!value || !value?.[0]) return null;
      if (isMulti) {
        return value.map((id) => ({ value: id, label: rolesMap[id.toString()]?.name }));
      }
      return { value: value[0], label: rolesMap[value[0]]?.name };
    },
    [isMulti, rolesMap]
  );

  const controlStyles = useCallback(
    (base: CSSObjectWithLabel, props: ControlProps<Option>) => ({
      ...base,
      height: props.getValue()?.length >= 3 && !props.isFocused ? '60px' : 'auto',
    }),
    []
  );

  const valueContainerStyles = useCallback(
    (base: CSSObjectWithLabel, props: ControlProps<Option>) => ({ ...base, height: '100%' }),
    []
  );

  return (
    <Select
      isLoading={!roles.length}
      isClearable
      styles={{
        control: controlStyles,
        valueContainer: valueContainerStyles,
      }}
      isMulti={isMulti}
      placeholder={placeholder}
      name="roles"
      options={options}
      value={getValue(value)}
      onChange={changeValue}
      defaultValue={getValue(defaultValue)}
    />
  );
};

export default RolesAsyncSearch;
