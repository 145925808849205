import { useEffect, useMemo, useState } from 'react';

import Select from 'react-select';

import { ConEdSupervisor } from 'types/Common/User';

import { UsersAPI } from 'Services/API';
import { useIsMount } from 'Utils/hooks/useMount';

interface Props {
  onSelect?: (item: ConEdSupervisor) => void;
  onClear?: () => void;
  placeholder?: string;
  isClearable?: boolean;
  defaultSupervisorId?: ConEdSupervisor['id'];
  defaultValue?: ConEdSupervisor;
  defaultInputValue?: string;
  departmentId?: number | string;
  value?: ConEdSupervisor;
  supervisorId?: ConEdSupervisor['id'];
}

const findById = (id) => (supervisor: ConEdSupervisor) => supervisor.id === id;

export const SupervisorsAsyncSearch = ({
  onSelect = () => {},
  onClear = () => {},
  placeholder,
  isClearable = true,
  defaultSupervisorId,
  defaultValue,
  defaultInputValue,
  departmentId,
  value,
  supervisorId,
}: Props) => {
  const [options, setOptions] = useState<ConEdSupervisor[]>([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState<ConEdSupervisor>(defaultValue);
  const [loading, setLoading] = useState(true);
  const isMount = useIsMount();

  const findSupervisors = async (value = '') => {
    const response = await UsersAPI.getSupervisors({
      department_id: departmentId || '',
    });

    return response.supervisors || [];
  };

  useEffect(() => {
    setLoading(true);
    findSupervisors()
      .then((supervisors) => {
        setOptions(supervisors);
        if (defaultSupervisorId) {
          setDefaultSelectValue(supervisors.find(findById(defaultSupervisorId)));
        }
      })
      .finally(() => setLoading(false));
  }, [departmentId]);

  const selectValue = useMemo(() => {
    if (value?.id && value?.name) {
      return value;
    }
    return options.find(findById(supervisorId)) || null;
  }, [value, supervisorId, options]);

  return (
    <Select<ConEdSupervisor, false>
      defaultValue={defaultSelectValue}
      value={selectValue}
      onChange={onSelect}
      options={options}
      isClearable={isClearable}
      placeholder={placeholder || 'Select supervisor'}
      styles={{ menu: (base) => ({ ...base, zIndex: 10 }) }}
      defaultInputValue={defaultInputValue}
      getOptionLabel={(supervisor) => supervisor?.name}
      isOptionSelected={(supervisor) => supervisor.id === (selectValue?.id || defaultSelectValue?.id)}
      isLoading={loading}
      isDisabled={!isMount && loading && !selectValue}
    />
  );
};
