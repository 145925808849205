import React, { useMemo } from 'react';

import { Typography } from '@mui/material';

import { ChangesLogFieldsItem } from 'types/Common/History';

import ChangesHistoryHelper from 'components/ChangesHistory/helpers';
import TextOverflowTooltip from 'components/TextOverflowTooltip';
import { useAppSelector } from 'createStore';

import styles from './FieldChangesLine.module.scss';

type Props = {
  field: ChangesLogFieldsItem;
  jobType?: string | number;
};

const FieldChangesLine: React.FC<Props> = ({ field, jobType = '' }) => {
  const departmentsMap = useAppSelector((state) => state.app.departmentsMap);

  const { fieldName, oldValue, newValue } = useMemo(() => {
    return new ChangesHistoryHelper(field, departmentsMap, jobType);
  }, [departmentsMap, field, jobType]);

  return (
    <li className={styles.fieldChangesLine}>
      <TextOverflowTooltip title={fieldName}>
        <article className={styles.fieldChangesLineItem}>{fieldName}</article>
      </TextOverflowTooltip>
      <article className={styles.fieldChangesLineItem}>{oldValue}</article>
      <article className={styles.fieldChangesLineItem}>{newValue}</article>
    </li>
  );
};

export default FieldChangesLine;
