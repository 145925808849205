import { EROLES } from 'Constants/user';
import { ReduxState } from 'createStore';

export function canAccessLimitDept(currentUser: ReduxState['app']['user']) {
  if (
    currentUser &&
    currentUser.roles &&
    (currentUser.roles.includes(EROLES.requestor) ||
      currentUser.roles.includes(EROLES.department_supervisor) ||
      currentUser.roles.includes(EROLES.coned_billing_admin))
  ) {
    if (
      currentUser.roles.includes(EROLES.dispatcher) ||
      currentUser.roles.includes(EROLES.dispatcher_supervisor) ||
      currentUser.roles.includes(EROLES.billing) ||
      currentUser.roles.includes(EROLES.superadmin) ||
      currentUser.roles.includes(EROLES.project_owner)
    ) {
      return false;
    }
    return true;
  }
  return false;
}
