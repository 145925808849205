import { FC, useEffect, useRef, useState } from 'react';

import moment from 'moment';

import { Box, Tooltip, Icon, Typography } from '@mui/material';

import { ChatMessage } from 'Services/API/JobsAPI.service';
import { FORMATS } from 'Utils/Date';
import { UserWorkerIcon, UserSolidBold } from 'Utils/Icon';

type Props = {
  message: ChatMessage;
  isFirstUserMessageInChain: boolean;
  nextMessageFromSameUser: boolean;
};

const ChatMessageBody: FC<Props> = ({
  message,
  isFirstUserMessageInChain = false,
  nextMessageFromSameUser = false,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={message.is_coned_supervisor ? 'flex-end' : 'flex-start'}
      gap={'10px'}
    >
      {isFirstUserMessageInChain && (
        <Box display="flex" color="text.secondary" alignItems="center" gap="6px">
          <Tooltip placement="top" title={message.is_coned_supervisor ? 'Coned Supervisor' : ''} disableInteractive>
            <Icon sx={{ width: 16, height: 16 }}>
              {message.is_coned_supervisor ? <UserWorkerIcon /> : <UserSolidBold />}
            </Icon>
          </Tooltip>

          <Typography color="text.primary" fontFamily={'Poppins Medium'} fontSize={15}>
            {message.user_name}
          </Typography>
        </Box>
      )}

      <Box
        display={'flex'}
        bgcolor={message.is_coned_supervisor ? 'primary.main' : 'secondary.light'}
        px={'16px'}
        pt={'12px'}
        pb={'20px'}
        position={'relative'}
        maxWidth={'min(90%, 550px)'}
        minWidth={'180px'}
        borderRadius={
          nextMessageFromSameUser ? '12px' : message.is_coned_supervisor ? '12px 0 12px 12px' : '0 12px 12px 12px'
        }
      >
        <Typography
          sx={{ overflowWrap: 'anywhere' }}
          fontSize={14}
          lineHeight={'22px'}
          color={message.is_coned_supervisor ? 'white' : 'black'}
        >
          {message.message}
        </Typography>

        <Typography
          position="absolute"
          bottom={6}
          right={10}
          lineHeight={1}
          color={message.is_coned_supervisor ? '#ffffff' : 'text.primary '}
          fontSize={11}
          whiteSpace={'nowrap'}
          fontFamily={message.is_coned_supervisor && 'Poppins Medium'}
        >
          {message.updated_at && !moment(message.updated_at).isSame(message.created_at) ? 'edited ' : ''}
          {moment(message.created_at).format(FORMATS.chatMessageCreateAt)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatMessageBody;
