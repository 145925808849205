import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';

import moment from 'moment';

import { AtLeastOne } from 'types/utils';

import Scheduling from '../types';
import {
  clearSchedule,
  createSchedule,
  deleteSchedule,
  deleteScheduleLocation,
  getAvailableLocations,
  getSchedule,
  getSchedules,
  getStats,
  getUnroutedFlaggingReport,
  routeSchedule,
  routeSchedules,
  updateSchedule,
} from './operations';

const initialState = {
  schedules: [] as Scheduling.ListItem[],
  stats: {
    gas_locations_per_zone: {
      all: [],
    },
    locations_per_zone: {
      all: [],
      flagging: [],
      parking: [],
    },
    total_schedules: {
      all: 0,
      avg_locations_per_schedule: 0,
    },
    types: {
      all: {
        all: 0,
        completed: 0,
        routed: 0,
      },
      flagging: {
        all: 0,
        completed: 0,
        routed: 0,
      },
      parking: {
        all: 0,
        completed: 0,
        routed: 0,
      },
    },
  } as Scheduling.Stats,
  company: {
    auto_routing_enabled: false,
  },
  config: {
    auto_routing_enabled: 0,
  },
  options: {
    date: moment().format('YYYY-MM-DD'),
    time_range: 'tomorrow',
  },
  schedule: {
    id: 0,
    date: null,
    first_name: '',
    last_name: '',
    scheduled_finish_at: null,
    scheduled_start_at: null,
    start_lat: 0,
    start_lon: 0,
    start_zip_code: 0,
    user_id: 0,
    stay_zone_id: 0,
    company_name: '',
    status: 'pending',
    phone_number: '',
  } as Scheduling.ListItem,
  scheduleLocations: [] as Scheduling.ScheduleLocation[],
  availableLocations: [] as Scheduling.ScheduleAssignLocation[],
  supervisor_location: {
    heading: 0,
    lat: 0,
    lon: 0,
    timestamp: '',
  } as Scheduling.SupervisorLocation,

  unroutedFlaggingLocations: [] as Scheduling.ReportItem[],
  lastUpdateUnroutedFlaggingLocations: new Date(),

  selectedLocationId: 0,

  schedule_processing: false,
  stats_processing: false,
  schedules_processing: false,
  create_schedule_processing: false,
  route_schedule_processing: false,
  update_schedule_processing: false,
  delete_schedule_processing: false,
  delete_schedule_location_processing: false,
  clear_schedule_processing: false,
  get_available_locations_processing: false,

  lastUpdateTime: moment(),

  showScheduleDetails: false,
  selectedZone: 0,

  automaticMessage: '',
};

type State = typeof initialState;

const slice = createSlice({
  name: 'scheduling',
  initialState,
  reducers: {
    changeOptions: (state, action: PayloadAction<AtLeastOne<State['options']>>) => {
      state.options = { ...state.options, ...action.payload };
    },
    showDetails: (state) => {
      state.showScheduleDetails = true;
    },
    hideDetails: (state) => {
      state.showScheduleDetails = false;
    },
    changeSelectedZone: (state, action: PayloadAction<number>) => {
      state.selectedZone = action.payload;
    },
    selectLocation: (state, { payload }: PayloadAction<number>) => {
      state.selectedLocationId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchedules.pending, (state, payload) => {
        if (payload.meta.arg) {
          return;
        }
        state.schedules_processing = true;
      })
      .addCase(getSchedules.fulfilled, (state, action) => {
        state.schedules = action.payload;
        state.schedules_processing = false;
      })
      .addCase(getSchedules.rejected, (state) => {
        state.schedules_processing = false;
      })
      .addCase(getStats.pending, (state) => {
        state.stats_processing = true;
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.stats = action.payload;
        state.stats_processing = false;
      })
      .addCase(getStats.rejected, (state) => {
        state.stats_processing = false;
      })
      .addCase(getSchedule.pending, (state, action) => {
        state.schedule_processing = true;
        const newSelectedSchedule = state.schedules.find((schedule) => schedule.id === action.meta.arg);
        if (state.schedule.id !== action.meta.arg) {
          state.selectedLocationId = 0;
        }
        state.schedule.id = action.meta.arg;
        if (newSelectedSchedule) {
          state.schedule.scheduled_start_at = newSelectedSchedule.scheduled_start_at;
          state.schedule.scheduled_finish_at = newSelectedSchedule.scheduled_finish_at;
          state.schedule.start_lat = newSelectedSchedule.start_lat;
          state.schedule.start_lon = newSelectedSchedule.start_lon;
          state.schedule.start_zip_code = newSelectedSchedule.start_zip_code;
          state.schedule.user_id = newSelectedSchedule.user_id;
          state.schedule.date = newSelectedSchedule.date;
          state.schedule.first_name = newSelectedSchedule.first_name;
          state.schedule.last_name = newSelectedSchedule.last_name;
        }
      })
      .addCase(getSchedule.fulfilled, (state, action) => {
        state.schedule = action.payload.schedule;
        state.scheduleLocations = action.payload.locations || [];
        state.supervisor_location = action.payload.supervisor_location;
        state.schedule_processing = false;
        state.automaticMessage = action.payload.sms_text;
        state.lastUpdateTime = moment();
      })
      .addCase(getSchedule.rejected, (state) => {
        state.schedule_processing = false;
      })
      .addCase(getAvailableLocations.pending, (state) => {
        state.get_available_locations_processing = true;
      })
      .addCase(getAvailableLocations.fulfilled, (state, action) => {
        state.availableLocations = action.payload.locations;
        state.get_available_locations_processing = false;
      })
      .addCase(getAvailableLocations.rejected, (state) => {
        state.get_available_locations_processing = false;
      })
      .addCase(deleteSchedule.pending, (state) => {
        state.delete_schedule_processing = true;
      })
      .addCase(deleteSchedule.fulfilled, (state, payload) => {
        state.delete_schedule_processing = false;
        if (state.schedule.id === payload.meta.arg) {
          state.schedule = initialState.schedule;
          state.scheduleLocations = initialState.scheduleLocations;
        }
      })
      .addCase(deleteSchedule.rejected, (state) => {
        state.delete_schedule_processing = false;
      })
      .addCase(getUnroutedFlaggingReport.fulfilled, (state, action) => {
        state.unroutedFlaggingLocations = action.payload.locations;
        state.lastUpdateUnroutedFlaggingLocations = new Date();
      });
    addLoader(builder, updateSchedule, 'update_schedule_processing');
    addLoader(builder, createSchedule, 'create_schedule_processing');
    addLoader(builder, routeSchedule, 'route_schedule_processing');
    addLoader(builder, clearSchedule, 'clear_schedule_processing');
    addLoader(builder, deleteScheduleLocation, 'delete_schedule_location_processing');
    addLoader(builder, routeSchedules, 'schedules_processing');
  },
});

export const schedulingReducer = slice.reducer;

export const SchedulingActions = slice.actions;

function addLoader<T extends ActionReducerMapBuilder<State>>(
  builder: T,
  action,
  processingKey: keyof State extends string ? keyof State : string
) {
  return builder
    .addCase(action.pending, (state) => {
      (state[processingKey] as boolean) = true;
    })
    .addCase(action.fulfilled, (state) => {
      (state[processingKey] as boolean) = false;
    })
    .addCase(action.rejected, (state) => {
      (state[processingKey] as boolean) = false;
    });
}
