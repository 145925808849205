import moment from 'moment';
import { ImmutableObject } from 'seamless-immutable';

import { DispatchJob, JobAlert } from 'types/Dispatch';

import UserPermissions from 'Utils/PermissionsHelper';
import { Departments } from 'Utils/departments';

export enum JOB_STATUSES {
  New,
  InProgress,
  Completed,
  Review,
  Billed,
  Paid,
  Cancelled,
  Update,
  AssignWorker,
  CancelledBillable = 7,
  OnHold = 9,
}

export enum JobType {
  Flagging = 1,
  Parking = 2,
  // The Signage type used only for requests to backend. Probably it will be changed to both SignUp & SignDown types
  Signage = 3,
  // Need to change this to SignsUp and SignsDown. After chnage - check all places where uses JobType
  'Signs Up' = 4,
  'Signs Down' = 5,
}

export enum MunicipalityName {
  Bronx = 'Bronx',
  Brooklyn = 'Brooklyn',
  Manhattan = 'Manhattan',
  Queens = 'Queens',
  StatenIsland = 'Staten Island',
  Westchester = 'Westchester',
}

export const POET_NUMBER = Object.freeze({
  [MunicipalityName.Bronx]: '22392897/0001',
  [MunicipalityName.Brooklyn]: '22392894/0001',
  [MunicipalityName.Manhattan]: '22392910/0001',
  [MunicipalityName.Queens]: '22392894/0001',
  [MunicipalityName.StatenIsland]: '22392908/0001',
  [MunicipalityName.Westchester]: '22392897/0001',
});

export type MunicipalityOptionType = {
  label: MunicipalityName;
  value: number;
};

export const MUNICIPALITY: MunicipalityOptionType[] = [
  { label: MunicipalityName.Bronx, value: 1 },
  { label: MunicipalityName.Brooklyn, value: 2 },
  { label: MunicipalityName.Manhattan, value: 3 },
  { label: MunicipalityName.Queens, value: 4 },
  { label: MunicipalityName.StatenIsland, value: 5 },
  { label: MunicipalityName.Westchester, value: 6 },
];

export const municipalitiesMap = MUNICIPALITY.reduce(
  (acc, mun) => ({
    ...acc,
    [mun.value]: mun,
  }),
  {} as { [key: number]: { label: string; value: number } }
);

export enum NOTIFIABLE_TYPES {
  CREATE_JOB = 1,
  CANCEL_JOB = 2,
  CREATE_INVOICE = 3,
  APPOINTED = 4,
  AWAITING_APROVAL = 5,
  EDIT_JOB = 6,
  ASSIGN_JOB = 7,
  WORKER_EN_ROUTE = 8,
  WORKER_ON_LOCATION = 9,
  WORKER_SECURED_SITE = 10,
  WORKER_UPLOAD_AN_IMAGE = 11,
  WORKER_ENDED_SHIFT = 12,
  PO_NUMBER_HAS_BEEN_ADDED = 13,
  REMINDER_EMAILS = 14,
}

export const OVERTIME_HOURS = 6;
export const OVERTIME_HOURS_INCREASE = 30;

export type JobStatusString =
  | 'new'
  | 'on_hold'
  | 'in_progress'
  | 'completed'
  | 'review'
  | 'billed'
  | 'paid'
  | 'cancelled'
  | 'cancelled_billable';

class JobStatusConverter {
  get NEW(): 'new' {
    return 'new';
  }
  get IN_PROGRESS(): 'in_progress' {
    return 'in_progress';
  }
  get COMPLETED(): 'completed' {
    return 'completed';
  }
  get REVIEW(): 'review' {
    return 'review';
  }
  get BILLED(): 'billed' {
    return 'billed';
  }
  get PAID(): 'paid' {
    return 'paid';
  }
  get CANCELLED(): 'cancelled' {
    return 'cancelled';
  }
  get CANCELLED_BILL(): 'cancelled_billable' {
    return 'cancelled_billable';
  }
  get ON_HOLD(): 'on_hold' {
    return 'on_hold';
  }

  // TODO: Separate this status from others
  get TIME_ENDING(): 'time_ending' {
    return 'time_ending';
  }

  get RELEASE_FROM_HOLD(): 'release_from_hold' {
    return 'release_from_hold';
  }

  private statusToStringMap = {
    [this.NEW]: 'New',
    [this.IN_PROGRESS]: 'In Progress',
    [this.COMPLETED]: 'Completed',
    [this.REVIEW]: 'Under Review',
    [this.BILLED]: 'Billed',
    [this.PAID]: 'Paid',
    [this.CANCELLED]: 'Cancelled',
    [this.CANCELLED_BILL]: 'Cancelled Billable',
    [this.TIME_ENDING]: 'Time Ending/Ended',
    [this.ON_HOLD]: 'On Hold',
  };
  private numStatusToStringMap = {
    [JOB_STATUSES.New]: this.NEW,
    [JOB_STATUSES.InProgress]: this.IN_PROGRESS,
    [JOB_STATUSES.Completed]: this.COMPLETED,
    [JOB_STATUSES.Review]: this.REVIEW,
    [JOB_STATUSES.Billed]: this.BILLED,
    [JOB_STATUSES.Paid]: this.PAID,
    [JOB_STATUSES.Cancelled]: this.CANCELLED,
    [JOB_STATUSES.CancelledBillable]: this.CANCELLED_BILL,
    [JOB_STATUSES.OnHold]: this.ON_HOLD,
  };
  parsed(status = '') {
    return this.statusToStringMap[status] || status;
  }
  parsedFromNumber(status = 0) {
    return this.parsed(this.numStatusToStringMap[status]);
  }

  fromNumber(status: JOB_STATUSES = 0): JobStatusString {
    return this.numStatusToStringMap[status];
  }
}

export const JobStatus = new JobStatusConverter();

export class CanDoJobActionHelper {
  #job: ImmutableObject<DispatchJob> = null;
  #jobTypeHelper: JobTypeHelper = null;
  #jobStatusHelper: JobStatusHelper = null;

  constructor(job: ImmutableObject<DispatchJob>) {
    this.#job = job;
    this.#jobTypeHelper = new JobTypeHelper(job?.type);
    this.#jobStatusHelper = new JobStatusHelper(job?.status);
  }

  get complete() {
    const { isNew, isInProgress, isReview } = this.#jobStatusHelper;
    return isNew || isInProgress || isReview;
  }

  get revive() {
    const { isCancelled, isCompleted, isCancelledBillable } = this.#jobStatusHelper;
    return isCancelled || isCompleted || isCancelledBillable;
  }

  get reconstruct() {
    const { isParking } = this.#jobTypeHelper;
    const { isCancelled, isCancelledBillable } = this.#jobStatusHelper;
    return UserPermissions.has.allow_reconstruct_job && isParking && (isCancelled || isCancelledBillable);
  }

  get putOnHold() {
    const { isNew, isInProgress } = this.#jobStatusHelper;
    return isNew || isInProgress;
  }

  get releaseFromHold() {
    return this.#jobStatusHelper.isOnHold;
  }

  get assignCompanies() {
    const { isNew, isInProgress } = this.#jobStatusHelper;
    return isNew || isInProgress;
  }

  get split() {
    const isJobDepartmentService = Departments.isService(this.#job.department_id);
    const { isFlagging, isParking } = this.#jobTypeHelper;

    return isJobDepartmentService && UserPermissions.has.can_split_job && (isFlagging || isParking);
  }

  get extendJobTime() {
    return this.#job.finish_at && UserPermissions.has.extend_job_end_time;
  }
  get sendNoCrewEmail() {
    const { isFlagging } = this.#jobTypeHelper;
    return Boolean(isFlagging && this.#job.shift_no_crew);
  }
}

export class JobTypeHelper {
  private _type: JobType;
  constructor(type: JobType | string) {
    if (typeof type === 'string') {
      this._type = JobType[type];
    } else {
      this._type = type;
    }
  }

  get isFlagging() {
    return this._type === JobType.Flagging;
  }

  get isParking() {
    return this._type === JobType.Parking;
  }

  get isSignage() {
    return [JobType['Signs Down'], JobType['Signs Up'], JobType.Signage].includes(this._type);
  }

  get isSignsUp() {
    return this._type === JobType['Signs Up'];
  }

  get isSignsDown() {
    return this._type === JobType['Signs Down'];
  }
}

export class JobStatusHelper {
  #status: JobStatusString;
  constructor(status: JobStatusString | JOB_STATUSES) {
    if (typeof status === 'string') {
      this.#status = status;
    } else {
      this.#status = JobStatus.fromNumber(status);
    }
  }

  get isNew() {
    return this.#status === JobStatus.NEW;
  }

  get isInProgress() {
    return this.#status === JobStatus.IN_PROGRESS;
  }

  get isCompleted() {
    return this.#status === JobStatus.COMPLETED;
  }

  get isReview() {
    return this.#status === JobStatus.REVIEW;
  }

  get isBilled() {
    return this.#status === JobStatus.BILLED;
  }

  get isPaid() {
    return this.#status === JobStatus.PAID;
  }

  get isCancelled() {
    return this.#status === JobStatus.CANCELLED;
  }

  get isCancelledBillable() {
    return this.#status === JobStatus.CANCELLED_BILL;
  }

  get isOnHold() {
    return this.#status === JobStatus.ON_HOLD;
  }
}

export enum JobAlertStatus {
  new = 'new',
  upcoming = 'upcoming',
  expired = 'expired',
}

export const JobALertsColorsMap = {
  [JobAlertStatus.new]: '#3CB371',
  [JobAlertStatus.upcoming]: '#fbab5d',
  [JobAlertStatus.expired]: '#ff0050',
} as const;

export const JobAlertsMUIColorsMap = {
  [JobAlertStatus.new]: 'success.dark',
  [JobAlertStatus.upcoming]: 'warning.dark',
  [JobAlertStatus.expired]: 'error.dark',
} as const;

export class JobAlertHelper {
  #alert: JobAlert;
  constructor(alert: JobAlert) {
    this.#alert = alert;
  }

  #getNowDateTimeZoneAmerica() {
    return moment().tz('America/New_York').format('MM/DD/YY LT');
  }

  get #alertStatus() {
    if (this.isUpcoming) {
      return JobAlertStatus.upcoming;
    }
    if (this.isExpired) {
      return JobAlertStatus.expired;
    }
    return JobAlertStatus.new;
  }

  get minutesToAlert() {
    return moment(this.#alert.alert_at).diff(this.#getNowDateTimeZoneAmerica(), 'minutes');
  }

  get isUpcoming() {
    return this.minutesToAlert > 0 && this.minutesToAlert <= 15;
  }

  get isExpired() {
    return this.minutesToAlert <= 0;
  }

  get message() {
    return this.#alert.alert_message;
  }

  get formattedAlertAt(): string {
    return moment(this.#alert.alert_at).format('MM/DD/YY LT');
  }

  get htmlColor() {
    return JobALertsColorsMap[this.#alertStatus];
  }

  get tooltipMessage() {
    return `${this.message}. Alert at ${this.formattedAlertAt}. (${
      !this.isExpired ? `${this.minutesToAlert} min` : `Expired`
    })`;
  }
}
