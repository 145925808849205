import { useEffect, useMemo, useState } from 'react';

import Select from 'react-select';

import { ConEdRequestor } from 'types/Common/User';

import { UsersAPI } from 'Services/API';
import { useIsMount } from 'Utils/hooks/useMount';
import { showErrorMessage } from 'Utils/errorMessage';

interface Props {
  onSelect?: (item: ConEdRequestor) => void;
  onClear?: () => void;
  placeholder?: string;
  isClearable?: boolean;
  searchParams?: any;
  defaultRequestorId?: ConEdRequestor['id'];
  defaultValue?: ConEdRequestor;
  defaultInputValue?: string;
  departmentId?: number | string;
  value?: ConEdRequestor;
  requestorId?: ConEdRequestor['id'];
}

const findById = (id) => (requestor: ConEdRequestor) => requestor.id === id;

const RequestorAsyncSearch = ({
  onSelect = () => {},
  onClear = () => {},
  placeholder,
  isClearable = true,
  searchParams = {},
  defaultRequestorId,
  defaultValue,
  defaultInputValue,
  departmentId,
  value,
  requestorId,
}: Props) => {
  const [options, setOptions] = useState<ConEdRequestor[]>([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState<ConEdRequestor>(defaultValue);
  const [loading, setLoading] = useState(true);
  const isMount = useIsMount();

  const findRequestors = async () => {
    const response = await UsersAPI.getRequestors({
      ...searchParams,
      department_id: departmentId || '',
    });

    return response?.results || [];
  };

  useEffect(() => {
    setLoading(true);
    findRequestors()
      .then((requestors) => {
        setOptions(requestors);
        if (defaultRequestorId) {
          setDefaultSelectValue(requestors.find(findById(defaultRequestorId)));
        }
      })
      .catch(showErrorMessage)
      .finally(() => setLoading(false));
  }, [departmentId]);

  const selectValue = useMemo(() => {
    if (value?.id && value?.name) {
      return value;
    }
    return options.find(findById(requestorId)) || null;
  }, [value, requestorId, options]);

  return (
    <Select<ConEdRequestor, false>
      defaultValue={defaultSelectValue}
      value={selectValue}
      onChange={onSelect}
      options={options}
      isClearable={isClearable}
      placeholder={placeholder || 'Select requester'}
      styles={{ menu: (base) => ({ ...base, zIndex: 10 }) }}
      defaultInputValue={defaultInputValue}
      getOptionLabel={(requestor) => requestor?.name}
      isOptionSelected={(requestor) => requestor.id === (selectValue?.id || defaultSelectValue?.id)}
      isLoading={loading}
      isDisabled={!isMount && loading && !selectValue}
    />
  );
};

export default RequestorAsyncSearch;
