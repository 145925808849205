import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';

import { Box, Typography } from '@mui/material';

import { JobsAPI } from 'Services/API';
import { GetChatsHistoryResponse } from 'Services/API/JobsAPI.service';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';

import ChatsHistorySkeleton from './ChatsHistory.Skeleton';
import ChatItem from './components/ChatItem';
import ChatMessages from './components/ChatMessages';
import ChatsTabs, { ChatsTabsEnum } from './components/ChatsTabs';
import ChatsTitle from './components/ChatsTitle';

type Props =
  | {
      jobId: number;
      updateTimeInterval?: number;
      chatsHistory?: never;
    }
  | {
      chatsHistory: GetChatsHistoryResponse;
      updateTimeInterval?: never;
      jobId?: never;
    };

const ChatsHistory: FC<Props> = ({
  jobId = null,
  updateTimeInterval = 0,
  chatsHistory = {
    supervisor_worker: [],
    supervisor_inspector: [],
  },
}) => {
  const [chats, setChats] = useState<GetChatsHistoryResponse>(chatsHistory);
  const [activeTab, setActiveTab] = useState(ChatsTabsEnum.worker);
  const [selectedWorkerChatID, setSelectedWorkerChatID] = useState<number>(null);
  const [selectedInspectorChatID, setSelectedInspectorChatID] = useState<number>(null);
  const { inProcess, promiseWrapper } = useProcessing();

  const selectedChatID = useMemo(() => {
    switch (activeTab) {
      case ChatsTabsEnum.worker:
        return selectedWorkerChatID;
      case ChatsTabsEnum.inspector:
        return selectedInspectorChatID;
      default:
        return null;
    }
  }, [activeTab, selectedWorkerChatID, selectedInspectorChatID]);

  const setSelectedChatID = (chatID: number) => {
    switch (activeTab) {
      case ChatsTabsEnum.worker:
        setSelectedWorkerChatID(chatID);
        break;
      case ChatsTabsEnum.inspector:
        setSelectedInspectorChatID(chatID);
        break;
      default:
        break;
    }
  };

  const chatItems = useMemo(() => {
    return Object.entries(chats[activeTab]);
  }, [chats, activeTab]);

  const updateChats = useCallback(() => {
    if (jobId) {
      return JobsAPI.getJobChatsHistory(jobId);
    }

    return Promise.resolve(chats);
  }, [jobId]);

  useEffect(() => {
    promiseWrapper(updateChats().then(setChats).catch(showErrorMessage));

    if (updateTimeInterval) {
      const updateChatsInterval = setInterval(
        () =>
          updateChats()
            .then((chats) => {
              setChats((prevChats) => {
                if (isEqual(prevChats, chats)) {
                  return prevChats;
                }
                return chats;
              });
            })
            .catch(showErrorMessage),
        updateTimeInterval
      );

      return () => clearInterval(updateChatsInterval);
    }
  }, [updateChats, updateTimeInterval]);

  return (
    <Box width="100%" height="100%" display="flex" bgcolor="white" borderRadius={'10px'} overflow="hidden">
      {/* Sidebar */}
      <Box
        width="100%"
        maxWidth={345}
        display="flex"
        flexDirection="column"
        p="24px"
        height="100%"
        borderRight="1px solid #00000010"
      >
        <ChatsTitle title="Chats history" />

        <ChatsTabs activeTab={activeTab} onSelectTab={setActiveTab} chats={chats} />

        <Box display="flex" flexDirection="column" gap="10px" flex={1} overflow="auto" py={1}>
          {chatItems.map(([id, messages]) => (
            <ChatItem
              key={id}
              chatID={id}
              userName={messages.at(-1)?.user_name}
              isConedSupervisor={Boolean(messages.at(-1)?.is_coned_supervisor)}
              onSelectChat={setSelectedChatID}
              isSelected={[selectedWorkerChatID, selectedInspectorChatID].includes(+id)}
              chatType={activeTab}
            />
          ))}

          {!chatItems.length && !inProcess && (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
              <Typography variant="body1" color="text.secondary">
                No chats
              </Typography>
            </Box>
          )}

          {inProcess && <ChatsHistorySkeleton />}
        </Box>
      </Box>
      {/* Main content */}
      {selectedChatID ? (
        <ChatMessages messages={chats?.[activeTab]?.[selectedChatID] || []} />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          <Typography variant="body1" color="text.secondary">
            Select chat to view messages
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default memo(ChatsHistory);
