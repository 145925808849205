import { ApiServiceHelper } from 'Services/HttpService';
import { SCHDEULE_BASE_ENDPOINT } from 'Services/endpoint';
import { createAppAsyncThunk } from 'Services/utils';
import { objectValuesFilter } from 'Utils/valueFilter';

import Scheduling from '../types';

const ScheduleService = new ApiServiceHelper(SCHDEULE_BASE_ENDPOINT);

export const getSchedules = createAppAsyncThunk(
  'scheduling/getSchedules',
  async (data?: { skipLoading: boolean } | void, thunkAPI?) => {
    const params = thunkAPI.getState().scheduling.options;
    const response = await ScheduleService.GET<{ supervisor_schedules: Scheduling.ListItem[] }>('/schedules', {
      date: params.date,
    });
    return response.supervisor_schedules || [];
  }
);

export const routeSchedules = createAppAsyncThunk('scheduling/routeSchedules', async (_, thunkAPI) => {
  const date = thunkAPI.getState().scheduling.options.date;
  const response = await ScheduleService.POST_JSON<{ schedule_ids: number[] }>(`/schedules/route`, { date });
  thunkAPI.dispatch(getSchedules({ skipLoading: true }));
  const currentScheduleId = thunkAPI.getState().scheduling.schedule.id;
  if (currentScheduleId) {
    thunkAPI.dispatch(getSchedule(currentScheduleId));
  }
  return response;
});

export const getStats = createAppAsyncThunk('scheduling/getStats', async (_, thunkAPI) => {
  const params = thunkAPI.getState().scheduling.options;
  const response = await ScheduleService.GET<Scheduling.Stats>('/schedules/stats', { date: params.date });
  return response;
});

export const getSchedule = createAppAsyncThunk('scheduling/getSchedule', async (id: number, thunkAPI) => {
  const response = await ScheduleService.GET<Scheduling.GetScheduleResponse>(`/schedules/${id}`);
  return response;
});

export const createSchedule = createAppAsyncThunk(
  'scheduling/createSchedule',
  async (data: Scheduling.CreateSchedule, thunkAPI) => {
    try {
      const response = await ScheduleService.POST_JSON<{ schedule_id: number }>(`/schedules`, data);
      thunkAPI.dispatch(getSchedules());
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifySchedule = createAppAsyncThunk(
  'scheduling/verifySchedule',
  async (data: Scheduling.CreateSchedule, thunkAPI) => {
    try {
      const response = await ScheduleService.POST_JSON<{ schedule_id: number }>(`/schedules/verify-new-schedule`, data);
      thunkAPI.dispatch(getSchedules());
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSchedule = createAppAsyncThunk(
  'scheduling/updateSchedule',
  async (data: Partial<Scheduling.ScheduleData> & { id: number }, thunkAPI) => {
    const update = objectValuesFilter(data, [undefined, data.id]);
    const response = await ScheduleService.POST_JSON<{ schedule_id: number }>(`/schedules/${data.id}`, update);
    thunkAPI.dispatch(getSchedules({ skipLoading: true }));
    thunkAPI.dispatch(getSchedule(data.id));
    return response;
  }
);

export const deleteSchedule = createAppAsyncThunk('scheduling/deleteSchedule', async (id: number, thunkAPI) => {
  await ScheduleService.DELETE<{ schedule_id: number }>(`/schedules/${id}`);
  thunkAPI.dispatch(getSchedules({ skipLoading: true }));
  return id;
});

export const routeSchedule = createAppAsyncThunk(
  'scheduling/routeSchedule',
  async (data: Scheduling.ScheduleData, thunkAPI) => {
    await ScheduleService.POST_JSON<{ schedule_id: number }>(`/schedules/${data.id}/route`, data);
    thunkAPI.dispatch(getSchedules({ skipLoading: true }));
    thunkAPI.dispatch(getSchedule(data.id));
  }
);

export const clearSchedule = createAppAsyncThunk(
  'scheduling/clearSchedule',
  async (data: Scheduling.ScheduleData, thunkAPI) => {
    await ScheduleService.POST_JSON<{ schedule_id: number }>(`/schedules/${data.id}/clear`, data);
    thunkAPI.dispatch(getSchedules({ skipLoading: true }));
    thunkAPI.dispatch(getSchedule(data.id));
  }
);

export const refetchSchedules = createAppAsyncThunk('scheduling/refetchSchedules', (_, thunkAPI) => {
  thunkAPI.dispatch(getSchedules({ skipLoading: true }));
  thunkAPI.dispatch(getStats());
  thunkAPI.dispatch(getUnroutedFlaggingReport(''));
  const scheduleId = thunkAPI.getState().scheduling.schedule.id;
  if (scheduleId) {
    thunkAPI.dispatch(getSchedule(scheduleId));
  }
});

export const updateScheduleLocation = createAppAsyncThunk(
  'scheduling/updateScheduleLocation',
  async (data: Scheduling.ScheduleLocation & { schedule_id: number }, thunkAPI) => {
    const locationData: Partial<Scheduling.ScheduleLocation> = {
      scheduled_start_at: data.scheduled_start_at,
      scheduled_finish_at: data.scheduled_finish_at,
      status: data.status,
    };
    await ScheduleService.POST_JSON<{ schedule_id: number }>(
      `/schedules/${data.schedule_id}/locations/${data.location_id}`,
      locationData
    );
    thunkAPI.dispatch(getSchedules({ skipLoading: true }));
    thunkAPI.dispatch(getSchedule(data.schedule_id));
  }
);

export const deleteScheduleLocation = createAppAsyncThunk(
  'scheduling/deleteScheduleLocation',
  async (data: Scheduling.ScheduleLocation & { schedule_id: number }, thunkAPI) => {
    await ScheduleService.DELETE<{ schedule_id: number }>(
      `/schedules/${data.schedule_id}/locations/${data.location_id}`
    );
    thunkAPI.dispatch(getSchedules({ skipLoading: true }));
    thunkAPI.dispatch(getSchedule(data.schedule_id));
  }
);

export const sendScheduleViaSms = createAppAsyncThunk(
  'scheduling/sendSmsToUser',
  async (data: { message: string; schedule_id: number }, thunkAPI) => {
    const response = await ScheduleService.POST_JSON<{ schedule_id: number }>(`/schedules/${data.schedule_id}/sms`);
    thunkAPI.dispatch(getSchedule(data.schedule_id));
    return response;
  }
);

export const getUnroutedFlaggingReport = createAppAsyncThunk(
  'scheduling/getUnroutedFlaggingReport',
  async (targetDate?: string | undefined, thunkAPI = null) => {
    const date = targetDate || thunkAPI?.getState().scheduling.options.date;
    const response = await ScheduleService.GET<{ locations: Scheduling.ReportItem[] }>(
      `/schedules/reports/unrouted-flagging`,
      { date }
    );
    return response;
  }
);

export const getAvailableLocations = createAppAsyncThunk(
  'scheduling/getAvailableLocations',
  async (scheduleId: number, thunkAPI) => {
    const response = await ScheduleService.POST<{ locations: Scheduling.ScheduleAssignLocation[] }>(
      `/schedules/${scheduleId}/search-available-locations`
    );
    return response;
  }
);

export const assignLocations = createAppAsyncThunk(
  'scheduling/assignLocations',
  async (data: Scheduling.AssignLocationsData, thunkAPI) => {
    try {
      const { schedule_id, ...restData } = data;
      const response = await ScheduleService.POST_JSON<{ schedule_id: number }>(
        `/schedules/${schedule_id}/assign-locations`,
        restData
      );
      thunkAPI.dispatch(getSchedule(schedule_id));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
