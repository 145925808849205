/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import TextField from '@mui/material/TextField';

import { EROLES } from 'Constants/user';
import { AppAPI } from 'Services/API';

interface Props {
  fullWidth?: boolean;
  onSelectRole?: (item: any) => void;
  onlyDispatcher?: boolean;
  defaultValue?: any;
  selectedRoleIds?: number[];
  limitTags?: any;
  noLabel?: boolean;
  small?: boolean;
  disabledValue?: any;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  id?: string;
}

export default function RolesMaterialAsyncSearch({
  onSelectRole,
  onlyDispatcher,
  defaultValue,
  selectedRoleIds = [],
  limitTags,
  noLabel,
  small,
  disabledValue,
  renderInput,
  fullWidth = false,
  id = 'roles-material-async-search',
}: Props) {
  const [roles, setRoles] = React.useState<any[]>([]);

  React.useEffect(() => {
    (async () => {
      const response = await AppAPI.getRoles();

      if (response) {
        let roles = response.map((role) => ({
          label: role.name,
          value: role,
        }));

        if (onlyDispatcher) {
          const filterRoles = [EROLES.dispatcher, EROLES.dispatcher_supervisor];

          // If current user is dispatcher supervider include aslo workers
          //if (authStore.isDispatchSupervisor()) {
          //  filterRoles.push(EROLES.worker);
          // }

          roles = roles.filter((item) => filterRoles.includes(item.value.id));
        }
        // delete worker role
        roles = roles.filter((item) => item.value.id !== EROLES.worker);

        setRoles(roles);
      }
    })();
  }, []);

  const defaultAutocompleteValue = React.useMemo(() => (defaultValue ? defaultValue : []), []);
  const value = React.useMemo(
    () =>
      selectedRoleIds.length
        ? selectedRoleIds.map((id) => roles.find((role) => role?.value?.id === id)).filter(Boolean)
        : [],
    [selectedRoleIds, roles]
  );

  return (
    <Autocomplete
      sx={{
        minWidth: 310,
        maxWidth: {
          xs: 'auto',
          sm: fullWidth ? '100%' : 310,
        },
        '@media (max-width: 900px)': {
          flex: 1,
        },
      }}
      multiple
      size={small ? 'small' : 'medium'}
      limitTags={limitTags ? limitTags : 1}
      defaultValue={defaultAutocompleteValue}
      value={value}
      id={id}
      getOptionDisabled={(option) => disabledValue && option.value.id === disabledValue.value.id}
      isOptionEqualToValue={(option, value) => option.value?.id === value.value?.id}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      renderOption={(props, option) => (
        <li {...props} key={option.value.id}>
          <span>{option.label}</span>
        </li>
      )}
      options={roles}
      loading={!roles.length}
      renderInput={
        renderInput
          ? (params) => renderInput(params)
          : (params) => (
              <TextField
                {...params}
                label={noLabel ? '' : 'Select Roles'}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    ...params.inputProps?.style,
                    minWidth: 10,
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                }}
              />
            )
      }
      onChange={(event, value) => onSelectRole(value)}
    />
  );
}
