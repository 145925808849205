import { alpha, createTheme } from '@mui/material';

import breakpoints from './breakpoints.module.scss';
import palette from './palette.module.scss';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    translucent: true;
  }
}

export default createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontFamily: 'Poppins Medium, system-ui, sans-serif',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            paddingTop: '7px',
            paddingBottom: '7px',
            '&[class*="MuiOutlinedInput-marginDense"]': {
              paddingTop: '7px',
              paddingBottom: '7px',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
        notchedOutline: {
          paddingTop: 12,
          paddingBottom: 12,
        },
        multiline: {
          '&.MuiOutlinedInput-marginDense': {
            paddingTop: 12,
            paddingBottom: 12,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          textTransform: 'capitalize',
          fontFamily: 'Poppins Medium, system-ui, sans-serif',
          boxShadow: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'translucent' },
          style: {
            fontSize: 13,
            lineHeight: '19.5px',
            '& .MuiButton-startIcon': {
              svg: {
                width: 14,
                height: 14,
              },
            },
          },
        },

        {
          props: { variant: 'translucent', color: 'primary' },
          style: {
            backgroundColor: alpha(palette.primaryMain, 0.1),
            color: palette.primaryMain,
            '&:hover': {
              backgroundColor: alpha(palette.primaryMain, 0.2),
            },
          },
        },
        {
          props: { variant: 'translucent', color: 'error' },
          style: {
            backgroundColor: alpha(palette.errorMain, 0.1),
            color: palette.errorDark,
            '&:hover': {
              backgroundColor: alpha(palette.errorMain, 0.2),
            },
          },
        },
        {
          props: { variant: 'translucent', color: 'success' },
          style: {
            backgroundColor: alpha(palette.successMain, 0.1),
            color: palette.successDark,
            '&:hover': {
              backgroundColor: alpha(palette.successMain, 0.2),
            },
          },
        },
        {
          props: { variant: 'translucent', color: 'secondary' },
          style: {
            backgroundColor: alpha(palette.secondaryMain, 0.1),
            color: palette.secondaryMain,
            '&:hover': {
              backgroundColor: alpha(palette.secondaryMain, 0.2),
            },
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins Medium, system-ui, sans-serif',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins Medium, system-ui, sans-serif',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Poppins Medium, system-ui, sans-serif',
          backgroundColor: palette.textPrimary,
          fontSize: 10,
          padding: '5px 10px',
          borderRadius: 6,
          lineHeight: '15px',
        },
        arrow: {
          color: palette.textPrimary,
          '&:before': {
            borderRadius: 1,
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: Number(breakpoints.xs),
      sm: Number(breakpoints.sm),
      md: Number(breakpoints.md),
      lg: Number(breakpoints.lg),
      xl: Number(breakpoints.xl),
    },
  },
  palette: {
    primary: {
      main: palette.primaryMain,
      light: palette.primaryLight,
    },
    secondary: {
      main: palette.secondaryMain,
      light: palette.secondaryLight,
    },
    error: {
      main: palette.errorMain,
      dark: palette.errorDark,
    },
    warning: {
      main: palette.warningMain,
      dark: palette.warningDark,
    },
    success: {
      main: palette.successMain,
      dark: palette.successDark,
    },
    text: {
      primary: palette.textPrimary,
      secondary: palette.textSecondary,
    },
  },
  typography: {
    fontFamily: 'Poppins Regular, system-ui, sans-serif',
    allVariants: {
      color: palette.textPrimary,
      letterSpacing: 0,
    },
    caption: {
      fontSize: 12,
      lineHeight: '18px',
      fontFamily: 'Poppins Medium, system-ui, sans-serif',
      color: palette.textPrimary,
    },
    h4: {
      fontSize: 16,
      lineHeight: '24px',
      fontFamily: 'Poppins Medium, system-ui, sans-serif',
      color: palette.textPrimary,
    },
  },
});
