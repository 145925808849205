import moment from 'moment';
import Immutable from 'seamless-immutable';

import { DispatchJob, DispatchLocation, DispatchTableJob } from 'types/Dispatch';
import { Job } from 'types/Job';
import { AssignedCompany } from 'types/Subcontractors';

import { JobAlertStatus } from 'Constants/job';
import { WORKERS_STATUS } from 'Constants/worker';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';
import { FILTERS_STORAGE_KEY } from './actions';

let jobs = null;
let job_ids = null;
let updated_jobs = null;
let filters = null;
let jobs_locations = null;
export const initialLocationsSearchOptions = {
  page: 1,
  per_page: 75,
  total: 100,
  worker_statuses:
    'unconfirmed_cancel,pending,assigned,en_route,on_location,secured,crew_arrived,cannot_secure,review,finished',
  job_statuses: 'new,on_hold,in_progress',
  job_types: '',
  search: '',
  from_datetime: moment().format('YYYY-MM-DD'),
  to_datetime: moment().format('YYYY-MM-DD'),
  filterByCompletionDate: false,
  zones: [],
  workers_unconfirmed: false,
  unconfirmed: false,
  order_by_field: '',
  order_direction: 'asc' as 'asc' | 'desc',
  needs_replacement: false,
  requested_job_closed: null as boolean | null,
  is_reroute: null as boolean | null,
  has_pending_timesheet: null as boolean | null,
  shift_time_exceeded: null as boolean | null,
  alert: null as JobAlertStatus,
  alert_dispatch_after_call: null as boolean | null,
  has_left_worksite: null as boolean | null,
  missing_gps: null as boolean | null,
  shift_no_crew: null as boolean | null,
  no_photos_jobs: null as boolean | null,
};

export const initialLocationsFiltersStats = {
  alert_dispatch_after_call: 0,
  shift_no_crew: 0,
  has_left_worksite: 0,
  requested_job_closed: 0,
  is_reroute: 0,
  needs_replacement: 0,
  shift_time_exceeded: 0,
  unconfirmed: 0,
  workers_unconfirmed: 0,
  no_photos_jobs: 0,
  new_alerts: 0,
  upcoming_alerts: 0,
  expired_alerts: 0,
  missing_gps: 0,
};

const initialState = Immutable({
  processing: false,
  search_options: {
    page: 0,
    per_page: 75,
    search: '',
    sort_by_type: false,
    limit: 5000,
    jobStatus: [0, 1],
    job_type: [],
    field_supervisor: true,
    requestor_roles: [] as number[],
    requestDate: {
      from: '',
      to: '',
    },
    searchByEffectiveRequestDate: false,
    createdDate: null as {
      from: string;
      to: string;
    },
    last_year_jobs_only: true,
  },
  // jobs locations
  jobs_locations: [] as DispatchTableJob[],
  new_shift_id: null,
  replace_worker_processing: false,
  selected_location: null,
  locations_processing: false,
  jobs_locations_processing_key: null,
  add_job_location_processing: false,
  location_job_processing: false,
  add_worker_processing: false,
  update_worker_processing: false,
  selected_location_id: 0,
  selected_location_job_id: 0,
  location_job: null as DispatchJob,
  create_job_processing: false,
  re_route_location: false,
  locations_search_options: initialLocationsSearchOptions,
  jobs: {
    results: [] as Job[],
    page: 0,
    totalPage: 0,
    total: 0,
    limit: 0,
  },
  stats: {
    total_jobs: 0,
    total_workers_assigned: 0,
    total_workers_needed: 0,
  },
  locations_filters_stats_processing: false,
  locations_filters_stats: initialLocationsFiltersStats,
  confirmed_jobs: [],
  unconfirmed_jobs: [],
  img_data: null,
  processing_key: null,
  confirm_jobs_processing: false,
  update_po_number_processing: false,
  job: null as Job,
  job_id: '',
  retrieve_job_processing: false,
  update_job_processing: false,
  total: 0,
  worker_playback_config: {
    shiftId: 0,
    location: null as DispatchLocation,
    workerName: '',
  },
  extendWMSEndDate: {
    show: false,
    processing: false,
  },
  assignCompanies: {
    show: false,
    jobId: 0,
    companies: [] as AssignedCompany[],
  },
});

//let new_job;

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    case actionTypes.GET_JOBS_REQUEST:
      return state.merge({
        processing: true,
        processing_key: action.processing_key,
      });
    case actionTypes.GET_JOBS_SUCCESS:
      if (action.processing_key === state.processing_key) {
        return state.merge({
          processing: false,
          jobs: action.response,
        });
      } else {
        return state;
      }
    case actionTypes.GET_JOBS_ERROR:
      return state.merge({
        processing: false,
      });

    case actionTypes.GET_JOBS_LOCATIONS_REQUEST:
      return state.merge({
        locations_processing: true,
        // page         : action['page'],
        jobs_locations_processing_key: action.processing_key,
        locations_filters_stats_processing: true,
      });
    case actionTypes.GET_JOBS_LOCATIONS_SUCCESS:
      if (action.processing_key === state.jobs_locations_processing_key) {
        return state.merge({
          locations_processing: false,
          jobs_locations: action.jobs,
          total: action.total,
        });
      } else {
        return state;
      }
    case actionTypes.GET_JOBS_LOCATIONS_ERROR:
      return state.merge({
        locations_processing: false,
        locations_filters_stats_processing: false,
      });

    // RETRIEVE_LOCATIONS_FILTERS_STATS
    case actionTypes.GET_JOBS_LOCATIONS_FILTERS_STATS:
      return state.merge({
        locations_filters_stats: action.stats,
        locations_filters_stats_processing: false,
      });

    // RETRIEVE_LOCATION_JOB
    case actionTypes.RETRIEVE_LOCATION_JOB_REQUEST:
      return state.merge({
        location_job_processing: true,
        selected_location_id: action.selected_location_id ? action.selected_location_id : state.selected_location_id,
        selected_location_job_id: action.job_id,
      });
    case actionTypes.RETRIEVE_LOCATION_JOB_SUCCESS:
      return state.merge({
        location_job_processing: false,
        location_job: action.job,
      });
    case actionTypes.RETRIEVE_LOCATION_JOB_ERROR:
      return state.merge({
        location_job_processing: false,
      });

    // RECONSTRUCT JOB
    case actionTypes.RECONSTRUCT_JOB_SUCCESS:
      return state.merge({
        location_job_processing: false,
        location_job: action.job,
      });
    case actionTypes.RECONSTRUCT_JOB_ERROR:
      return state.merge({
        location_job_processing: false,
      });

    // INTEGRATION RE-ROUTE
    case actionTypes.INTEGRATION_REROUTE_SUCCESS:
      return state.merge({
        location_job_processing: false,
        location_job: action.job,
      });
    case actionTypes.INTEGRATION_REROUTE_ERROR:
      return state.merge({
        location_job_processing: false,
      });

    // ADD_WORKER
    case actionTypes.ADD_WORKER_REQUEST:
      return state.merge({
        add_worker_processing: true,
      });
    case actionTypes.ADD_WORKER_SUCCESS:
      return state.merge({
        add_worker_processing: false,
        location_job: action.job,
      });
    case actionTypes.ADD_WORKER_ERROR:
      return state.merge({
        add_worker_processing: false,
      });

    // UPDATE_JOB_STATUS_REQUEST
    case actionTypes.UPDATE_JOB_STATUS_REQUEST:
      return state.merge({});
    case actionTypes.UPDATE_JOB_STATUS_SUCCESS:
      // TODO: fix this
      jobs_locations = state.jobs_locations.asMutable();
      jobs = state.jobs.asMutable();
      jobs_locations.map((shift, index) => {
        action.job.workers.map((job_worker) => {
          if (job_worker.id === shift.id) {
            jobs_locations[index] = {
              ...shift,
              ...job_worker,
            };
          }
        });
      });

      jobs.results.map((job, index) => {
        action.job.workers.map((job_worker) => {
          if (job_worker.job_id === job.id) {
            job = {
              ...job,
              ...job_worker.status,
            };
          }
        });
      });

      return state.merge({
        location_job: action.job,
        jobs_locations: jobs_locations,
        jobs: jobs,
      });
    case actionTypes.UPDATE_JOB_STATUS_ERROR:
      return state.merge({});

    // UPDATE_JOB_WORKER
    case actionTypes.UPDATE_JOB_WORKER_REQUEST:
      return state.merge({
        update_worker_processing: true,
      });
    case actionTypes.UPDATE_JOB_WORKER_SUCCESS:
      // TODO: fix this
      jobs_locations = state.jobs_locations.asMutable();
      jobs_locations.map((shift, index) => {
        if (action.shift && shift.id === action.shift.id) {
          shift = {
            ...shift,
            ...action.shift,
          };
          jobs_locations[index] = shift;
        }
        action.job.workers.map((job_worker) => {
          if (job_worker.id === shift.id) {
            jobs_locations[index] = {
              ...shift,
              ...job_worker,
            };
          }
        });
      });

      updated_jobs = JSON.parse(JSON.stringify(state.jobs));
      updated_jobs.results.map((job, index) => {
        if (job.id === action.job.id) {
          action.job.workers.map((job_worker) => {
            job.workers.map((w, i) => {
              if (
                job_worker.worker_id === w.id &&
                job_worker.location_id === w.locationID &&
                job_worker.id === w.shift_id
              ) {
                updated_jobs.results[index].workers[i] = {
                  ...w,
                  status: WORKERS_STATUS.indexOf(job_worker.status),
                  workerStatus: job_worker.status,
                };
              }
            });
          });
        }
      });

      return state.merge({
        update_worker_processing: false,
        location_job: action.job,
        jobs_locations: jobs_locations,
        jobs: updated_jobs,
        //job: new_job,
      });
    case actionTypes.UPDATE_JOB_WORKER_ERROR:
      return state.merge({
        update_worker_processing: false,
      });

    // ADD_JOB_LOCATION
    case actionTypes.ADD_JOB_LOCATION_REQUEST:
      return state.merge({
        add_job_location_processing: true,
      });
    case actionTypes.ADD_JOB_LOCATION_SUCCESS:
      return state.merge({
        add_job_location_processing: false,
        location_job: action.job,
      });
    case actionTypes.ADD_JOB_LOCATION_ERROR:
      return state.merge({
        add_job_location_processing: false,
      });
    // ADD SHIFT
    case actionTypes.ADD_SHIFT_SUCCESS:
      return state.merge({
        location_job: action.job,
      });

    // REPLACE WORKER
    case actionTypes.REPLACE_JOB_WORKER_REQUEST:
      return state.merge({
        replace_worker_processing: true,
        add_worker_processing: true,
      });
    case actionTypes.REPLACE_JOB_WORKER_SUCCESS:
      return state.merge({
        replace_worker_processing: false,
        add_worker_processing: false,
        new_shift_id: action.shift.id,
        location_job: action.shift,
      });
    case actionTypes.REPLACE_JOB_WORKER_ERROR:
      return state.merge({
        replace_worker_processing: false,
        add_worker_processing: false,
      });

    // UPDATE JOB
    case actionTypes.UPDATE_JOB_LOCATION_REQUEST:
      return state.merge({
        add_job_location_processing: true,
      });
    case actionTypes.UPDATE_JOB_LOCATION_SUCCESS:
      return state.merge({
        add_job_location_processing: false,
        location_job: action.job,
      });
    case actionTypes.UPDATE_JOB_LOCATION_ERROR:
      return state.merge({
        add_job_location_processing: false,
      });

    // DELETE LOCATION
    case actionTypes.DELETE_JOB_LOCATION_REQUEST:
      return state.merge({
        add_job_location_processing: true,
      });
    case actionTypes.DELETE_JOB_LOCATION_SUCCESS:
      // TODO: fix this
      jobs_locations = state.jobs_locations.asMutable();
      jobs_locations = jobs_locations.filter((location) => action.job.location_id !== location.location_id);
      const newJob = state.location_job.asMutable();
      newJob.locations = newJob.locations.filter((location) => action.job.location_id !== location.id);
      updated_jobs = JSON.parse(JSON.stringify(state.jobs));
      updated_jobs.results.map((job, index) => {
        if (job.id === action.job.job_id) {
          updated_jobs.results[index].locations = updated_jobs.results[index].locations.filter(
            (location) => action.job.location_id !== location.id
          );
        }
      });
      return state.merge({
        update_worker_processing: false,
        location_job: newJob,
        jobs_locations: jobs_locations,
        jobs: updated_jobs,
      });
    case actionTypes.DELETE_JOB_LOCATION_ERROR:
      return state.merge({
        add_job_location_processing: false,
      });

    // RETRIEVE JOB
    case actionTypes.RETRIEVE_JOB_REQUEST:
      return state.merge({
        retrieve_job_processing: true,
      });
    case actionTypes.RETRIEVE_JOB_SUCCESS: {
      // TODO: fix this
      jobs = JSON.parse(JSON.stringify(state.jobs));
      jobs.results.map((job, index) => {
        if (job.id === action.job.id) {
          jobs.results[index] = action.job;
        }
      });
      if (!jobs.results.length) {
        jobs.results = [action.job];
      }
      return state.merge({
        retrieve_job_processing: false,
        job: action.job,
        jobs: jobs,
      });
    }
    case actionTypes.RETRIEVE_JOB_ERROR:
      return state.merge({
        retrieve_job_processing: false,
      });

    // CLEAR JOB
    case actionTypes.CLEAR_JOB:
      return state.merge({
        job: initialState.job,
      });

    // UPDATE JOB
    case actionTypes.UPDATE_JOB_REQUEST:
      return state.merge({
        update_job_processing: true,
      });
    case actionTypes.UPDATE_JOB_SUCCESS:
      // TODO: fix this
      updated_jobs = JSON.parse(JSON.stringify(state.jobs));
      updated_jobs.results.map((job, index) => {
        if (job.id === action.job.id) {
          updated_jobs.results[index] = {
            ...job,
            ...action.job,
          };
          //new_job = updated_jobs.results[index];
        }
      });
      return state.merge({
        update_job_processing: false,
        job: action.job,
        jobs: updated_jobs,
      });
    case actionTypes.UPDATE_JOB_ERROR:
      return state.merge({
        update_job_processing: false,
      });

    // UPDATE JOB WORKERS
    case actionTypes.UPDATE_JOB_WORKERS_REQUEST:
      return state.merge({
        update_job_processing: true,
      });
    case actionTypes.UPDATE_JOB_WORKERS_SUCCESS:
      // TODO: fix this
      updated_jobs = JSON.parse(JSON.stringify(state.jobs));
      updated_jobs.results.map((job, index) => {
        if (job.id === action.job.id) {
          updated_jobs.results[index] = {
            ...job,
            ...action.job,
          };
          updated_jobs.results[index].workers.map((w, i) => {
            if (w.id === action.job.workerAction.id && w.locationID === action.job.workerAction.locationID) {
              updated_jobs.results[index].workers[i] = {
                ...w,
                ...action.job.workerAction,
              };
            }
            //new_job = updated_jobs.results[index];
          });
        }
      });
      let updated_job = JSON.parse(JSON.stringify(state.job));
      if (updated_job) {
        updated_job = {
          ...state.job,
          workers: action.job.workers,
        };
      }
      return state.merge({
        update_job_processing: false,
        job: updated_job,
        jobs: updated_jobs,
      });
    case actionTypes.UPDATE_JOB_WORKERS_ERROR:
      return state.merge({
        update_job_processing: false,
      });

    case actionTypes.CALCULATE_JOBS_STATS:
      return state.merge({
        stats: {
          ...state.stats,
          ...action.stats,
        },
      });
    case actionTypes.SOCKET_JOB_UPDATED:
      return state;

    // CONFIRM JOBS
    case actionTypes.CONFIRM_JOB_REQUEST:
      return state.merge({
        confirm_jobs_processing: true,
      });
    case actionTypes.CONFIRM_JOB_SUCCESS:
      // TODO: fix this
      jobs = state.jobs.results;
      job_ids = action.job_ids;
      updated_jobs = jobs.map((job) =>
        job_ids.indexOf(job.id) !== -1
          ? {
              ...job,
              confirmed: 1,
            }
          : job
      );

      const job = state.job.asMutable();
      if (job) {
        job_ids.forEach((job_id) => {
          if (job_id === job.id.toString()) {
            job.confirmed = 1;
          }
        });
      }

      return state.merge({
        jobs: {
          ...state.jobs,
          results: updated_jobs,
        },
        confirm_jobs_processing: false,
        job: job,
      });
    case actionTypes.CONFIRM_JOB_ERROR:
      return state.merge({
        confirm_jobs_processing: false,
      });

    // UPDATE PO NUMBER
    case actionTypes.UPDATE_PO_NUMBER_REQUEST:
      return state.merge({
        update_po_number_processing: true,
      });
    case actionTypes.UPDATE_PO_NUMBER_SUCCESS:
      // TODO: fix this
      jobs = state.jobs.results;
      job_ids = action.job_ids;
      updated_jobs = jobs.map((job) =>
        job_ids.indexOf(job.id) !== -1
          ? {
              ...job,
              po: action.update_po ? action.po_number : job.po,
              requisition: action.update_requisition ? action.requisition : job.requisition,
              receipt_number: action.update_receipt ? action.receipt : job.receipt_number,
              wr: action.update_wr ? action.wr : job.wr,
            }
          : job
      );
      return state.merge({
        jobs: {
          ...state.jobs,
          results: updated_jobs,
        },
        update_po_number_processing: false,
      });
    case actionTypes.UPDATE_PO_NUMBER_ERROR:
      return state.merge({
        update_po_number_processing: false,
      });

    case actionTypes.UPDATE_FILTERS:
      filters = {
        ...state.search_options,
        ...action.filters,
      };
      localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));
      return state.merge({
        search_options: filters,
      });
    case actionTypes.UPDATE_LOCATIONS_FILTERS:
      filters = {
        ...state.locations_search_options,
        ...action.filters,
      };
      return state.merge({
        locations_search_options: filters,
      });

    case actionTypes.SORT_ASAP:
      return state.merge({
        jobs: {
          ...state.jobs,
          results: action.jobs,
        },
      });

    // UPLOAD IMAGE
    case actionTypes.JOB_UPLOAD_IMAGE_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.JOB_UPLOAD_IMAGE_SUCCESS:
      return state.merge({
        processing: false,
        img_data: action.img_data,
      });
    case actionTypes.JOB_UPLOAD_IMAGE_ERROR:
      return state.merge({
        processing: false,
      });
    // CREATE JOB
    case actionTypes.JOB_CREATE_REQUEST:
      return state.merge({
        create_job_processing: true,
      });
    case actionTypes.JOB_CREATE_SUCCESS:
      return state.merge({
        create_job_processing: false,
        job_id: action.job.id,
      });
    case actionTypes.JOB_CREATE_ERROR:
      return state.merge({
        create_job_processing: false,
      });

    // OPEN WORKER PLAYBACK
    case actionTypes.OPEN_WORKER_PLAYBACK_MAP:
      const location = state.location_job.locations.find(({ id }) => id === action.locationId);
      return state.merge({
        worker_playback_config: {
          shiftId: action.shiftId,
          location,
          workerName: action.workerName,
        },
      });
    case actionTypes.CLOSE_WORKER_PLAYBACK_MAP:
      return state.merge({
        worker_playback_config: {
          shiftId: 0,
          location: null,
        },
      });

    // EXTEND JOB TIME MODAL
    case actionTypes.OPEN_EXTEND_JOB_TIME:
      return state.merge({
        extendWMSEndDate: {
          ...state.extendWMSEndDate,
          show: true,
        },
      });
    case actionTypes.CLOSE_EXTEND_JOB_TIME:
      return state.merge({
        extendWMSEndDate: {
          ...state.extendWMSEndDate,
          show: false,
        },
      });

    case actionTypes.EXTEND_WMS_JOB_END_TIME_REQUEST:
      return state.merge({ extendWMSEndDate: { ...state.extendWMSEndDate, processing: true } });
    case actionTypes.EXTEND_WMS_JOB_END_TIME_SUCCESS:
    case actionTypes.EXTEND_WMS_JOB_END_TIME_ERROR:
      return state.merge({ extendWMSEndDate: { ...state.extendWMSEndDate, processing: false } });

    // ASSIGN COMPANIES MODAL
    case actionTypes.OPEN_ASSIGN_COMPANIES:
      const jobId: number = action.jobId || state.assignCompanies.jobId;
      const companies: AssignedCompany[] = action.companies || state.assignCompanies.companies;
      return state.merge({
        assignCompanies: {
          show: true,
          jobId,
          companies,
        },
      });
    case actionTypes.CLOSE_ASSIGN_COMPANIES:
      return state.merge({
        assignCompanies: {
          show: false,
          companies: [],
          jobId: 0,
        },
      });

    case LOGOUT:
      return state.merge(initialState);

    default:
      return state;
  }
}
