import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { toast } from 'react-toastify';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { ReactComponent as CancelIcon } from 'Assets/icons/cancel-icon.svg';
import { baseToastConfig } from 'Constants/app';
import { actions } from 'Services';
import { parseErrorMessage } from 'Utils/errorMessage';
import Button from 'components/Button/Button';

import './CancelJobReason.scss';

interface Props extends ReduxProps {
  open: boolean;
  onClose: () => void;
  job_id: number;
  /** @default 'cancel' */
  status?: 'cancel' | 'cancel_billable';
}

interface State {
  reason: string;
  processing: boolean;
}

class CancelJobReason extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    status: 'cancel',
  };

  state = {
    reason: '',
    processing: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.job_id !== prevProps.job_id) {
      this.setState({
        reason: '',
      });
    }
  }

  confirmCancel = () => {
    this.setState({ processing: true });
    const { status, job_id, updateJobStatus } = this.props;
    const { reason: note } = this.state;
    updateJobStatus(job_id, { status, note })
      .then(() => this.props.onClose())
      .catch((e) => toast.error(parseErrorMessage(e), baseToastConfig))
      .finally(() => this.setState({ processing: false }));
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ reason: event.target.value });

  render() {
    const { reason, processing } = this.state;

    const requiredReason = this.props.status === 'cancel';
    const minRequiredReasonLength = 16;
    return (
      <>
        <Dialog onClose={this.props.onClose} aria-labelledby="simple-dialog-title" open={Boolean(this.props.open)}>
          <DialogTitle className={'cancel-title'}>Job Cancel</DialogTitle>

          <DialogContent>
            <Box className={'cancel-image-group'} mt={2} mb={2}>
              <CancelIcon />
            </Box>
            <Box mb={1}>
              <Typography variant="body2" color="textSecondary">
                Please write the reason here...
              </Typography>
            </Box>
            <Box mb={3}>
              <TextField
                id="cancel-job-reason"
                label="Reason"
                multiline
                error={requiredReason && reason.length <= minRequiredReasonLength}
                maxRows={3}
                style={{ width: '100%' }}
                variant="outlined"
                value={reason}
                onChange={this.handleChange}
                helperText={requiredReason ? `Required at least ${minRequiredReasonLength} characters` : ''}
              />
            </Box>
          </DialogContent>

          <DialogActions className={'action-button-group'}>
            <Button
              color={'gray'}
              width={'158px'}
              borderRadius={'20px'}
              textTransform={false}
              onClick={this.props.onClose}
            >
              Don't Cancel
            </Button>
            <Button
              color={'dark'}
              width={'158px'}
              borderRadius={'20px'}
              disabled={requiredReason ? reason.length <= minRequiredReasonLength : false}
              textTransform={false}
              processing={processing}
              onClick={this.confirmCancel}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateJobStatus: (job_id: number, options: { status: 'cancel' | 'cancel_billable'; note?: string }) =>
      dispatch(actions.JobsActions.updateJobStatus(job_id, options)),
  };
}

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CancelJobReason);
